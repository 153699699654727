import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text } from 'components/atoms';
import WorkflowItem from 'components/molecules/WorkflowItem';
import TagsContainer from 'components/molecules/TagsContainer';

import { htmlCheck } from 'utils/html';

const Workflow = ({ data, className, industry, spacer }) => {
  const { caption, steps } = data;
  const { title, subtitle } = caption;

  const step = steps?.map((stepInfo, id) => (
    <WorkflowItem data={stepInfo} key={id} />
  ));

  const headerTitle = htmlCheck(title);
  const headerSubtitle = htmlCheck(subtitle);

  return (
    <Section
      className={cn('workflow', { workflow_spacer: spacer }, [className])}
    >
      <Container>
        <Row>
          <Column
            col="12"
            lg="6"
            className={cn('workflow__header', `offset-lg-${industry ? 2 : 1}`)}
          >
            {headerTitle ? (
              <TagsContainer className={cn('tags-container_team')}>
                {title}
              </TagsContainer>
            ) : (
              <Text tag="h2" className="workflow__header__title">
                {title}
              </Text>
            )}
            {headerSubtitle ? (
              <TagsContainer className={cn('tags-container_workflow')}>
                {subtitle}
              </TagsContainer>
            ) : (
              <Text className="workflow__header__subtitle">{subtitle}</Text>
            )}
          </Column>
          <Column
            col="12"
            lg="7"
            className={cn(`offset-lg-${industry ? 2 : 1}`)}
          >
            {step}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

Workflow.defaultProps = {
  className: '',
  spacer: false,
};

Workflow.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  spacer: PropTypes.bool,
};

export default Workflow;
